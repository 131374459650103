/*
 * 업무구분: 고객
 * 화 면 명: MSPCM242P
 * 화면설명: 수익증권상세조회
 * 접근권한: 
 * 작 성 일: 2023.01.16
 * 작 성 자: 김연숙
 */
<template>
  <ur-page-container title="수익증권상세조회" :show-title="true" type="subpage" class="msp">
    <ur-box-container direction="column" alignV="start" class="msp-cm-wrap">
      <ur-box-container alignV="start" componentid="" direction="column" class="msp-detail-lst mt30">  
        <ul class="lst">
          <li>
            <span class="tit">펀드명</span>
            <span class="txt">{{accountInfo.fndNm}}</span>
          </li>
          <li>
            <span class="tit">신규일자</span>
            <span class="txt">{{$commonUtil.dateDashFormat(accountInfo.accnNewYmd)}}</span>
          </li>
          <li>
            <span class="tit">만기일자</span>
            <span class="txt">{{$commonUtil.dateDashFormat(accountInfo.accnExpYmd)}}</span>
          </li>
          <li>
            <span class="tit">인터넷여부</span>
            <span class="txt">{{accountInfo.cyberWndDlYn}}</span>
          </li>
          <li>
            <span class="tit">CMS여부</span>
            <span class="txt">{{accountInfo.cmsAplYn}}</span>
          </li>
          <li>
            <span class="tit">관리점</span>
            <span class="txt">{{accountInfo.mngShopNm}}</span>
          </li>
          <li>
            <span class="tit">관리자</span>
            <span class="txt">{{accountInfo.mngrNm}}</span>
          </li>
          <li>
            <span class="tit">연계계좌번호</span>
            <span class="txt">{{accountInfo.linkAccnNo}}</span>
          </li>
          <li>
            <span class="tit">휴대폰번호</span>
            <span class="txt">{{accountInfo.custCelno}}</span>
          </li>
        </ul>
      </ur-box-container>
      <ur-box-container alignV="start" componentid="" direction="column" class="ns-btn-relative">
        <div class="relative-div">
          <mo-button componentid="" color="primary" shape="border" size="medium" class="ns-btn-round blue" @click="fn_Back">확인</mo-button>
        </div>
      </ur-box-container>
    </ur-box-container>
  </ur-page-container>
</template>
<script>
  
  /***********************************************************************************
   * 공통 라이브러리 INCLUDE 영역	                                                   *
   ***********************************************************************************/
  export default {
    /***********************************************************************************
     * Vue 파일 속성 정의 영역	                                                       *
     ***********************************************************************************/
    name: "MSPCM242P",
    screenId: "MSPCM242P",
    props: {
      pAcountInfo: { // 조직정보
        type: Object,
        default: {}
      }
    },

    components: {
    },

    // bottom-sheet Full Popup
    modalProps: {
      mode: 'right' // 우->좌 open popup (defalut: 하->상)
    },

    /***********************************************************************************
     * 화면변수 선언 영역	                                                             *
     ***********************************************************************************/
    data() {
      return {
        accountInfo: {},
      };
    },

    /***********************************************************************************
     * Life Cycle 함수 정의 영역	                                                     *
     ***********************************************************************************/

    /** 설명 : Step-4 마운트 된 후 호출 */
    mounted () {
      // 화면접속로그 등록(필수)
      this.$bizUtil.insSrnLog(this.$options.screenId)
      this.accountInfo = this.$props.pAcountInfo
    },

    /***********************************************************************************
     * 사용자 함수 정의 영역                                                           *
     ***********************************************************************************/
    methods: {
      /*****************************************
       * Function명  : fn_Back
       * 설명        : 뒤로가기 (history back)
       *****************************************/
      fn_Back () {
        this.$emit('onPopupClose');
      },
    }
  };
</script>
<style scoped>
</style>