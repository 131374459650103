/*
 * 업무구분: 고객
 * 화 면 명: MSPCM240M
 * 화면설명: 수익증권추천고객
 * 작 성 일: 2023.01.16
 * 작 성 자: 김연숙
 */
<template>
  <ur-page-container class="msp" title="수익증권추천고객" :show-title="true" type="subpage" :action-type="actionSlot"
    @on-header-left-click="fn_HistoryBack"
    @on-scroll-bottom="fn_LoadingData()" :topButton="true">
    
    <template #frame-header-fixed>
      <ur-box-container v-if="showReturnStr && !isInit" alignV="start"  direction="column" class="search-result-box bd-b-Ty1"> 
        <strong class="tit">검색결과</strong>
        <mo-tab-box default-idx="1" primary class="ns-tabs-box-o nw-tab-sub ns-tabs-box2 no-tab">
          <mo-tab-label idx="1" v-if="isMngr && pOrgData240M !== {}" @click="fn_OpenMSPCM116P">
            <span class="txt">{{pOrgData240M.hofOrg.label}}</span> 
          </mo-tab-label>
          <mo-tab-label idx="1" v-if="isMngr && pOrgData240M !== {}" @click="fn_OpenMSPCM116P">
            <span class="txt">{{pOrgData240M.dofOrg.label}}</span> 
          </mo-tab-label>
          <mo-tab-label idx="1" v-if="isMngr && pOrgData240M !== {}" @click="fn_OpenMSPCM116P">
            <span class="txt">{{pOrgData240M.fofOrg.label}}</span> 
          </mo-tab-label>
          <mo-tab-label idx="1" v-if="isMngr && pOrgData240M !== {}" @click="fn_OpenMSPCM116P">
            <span class="tit">컨설턴트</span>
            <span class="txt">{{pOrgData240M.cnslt.label}}</span> 
          </mo-tab-label>
          <mo-tab-label idx="1" v-show="lv_ReturnStr[0] !== ''" @click="fn_OpenMSPCM116P">
            <span class="tit" v-if="isMngr">고객</span>
            <span class="txt">{{lv_ReturnStr[0]}}</span> 
          </mo-tab-label>
        </mo-tab-box>
      </ur-box-container>
      <ur-box-container alignV="start" componentid="" direction="column" class="ns-check-sum">
        <mo-list-item>
          <mo-checkbox v-model="isOneOrMoreCheck" @click="fn_CheckAll()" :disabled="checkDisabled || isEmptyView" size="small" />
          <div class="list-item__contents">
            <div class="list-item__contents__title">
              <span class="sum" v-if="checkCount === 0">총 <strong class="crTy-blue3">{{totCnt}}</strong> 명</span>
              <span class="sum" v-else><strong class="crTy-blue3">{{checkCount}}</strong> 명 선택</span>
            </div>
          </div>
        </mo-list-item>
      </ur-box-container>
    </template>
    <!-- Content영역 -->
    <ur-box-container direction="column" alignV="start" class="msp-cm-wrap"> 
      <ur-box-container v-if="!isEmptyView" alignV="start" componentid="" direction="column" class="ns-check-list">
        <mo-list :list-data="cMBfcerRecmCustSVO">
          <template #list-item="{index, item}">
            <mo-list-item :class="{'on' : item.checked === true}" @click.native.stop="fn_OnClickListItem(index)" expandable prevent-expand>
              <mo-checkbox v-model="item.checked" @input="fn_CheckSelectedItemCount(item)" :disabled="checkDisabled" :ref="`chk${index}`"/>
              <!-- 고객명 -->
              <div class="list-item__contents">
                <div class="list-item__contents__title">
                  <!-- 고객카드 없음 240129 추가 -->
                  <span class="name" style="display: flex; padding-right: 18px;">
                    <span class="txtSkip" @click.stop="fn_OpenCustInfoBs(item)">
                      {{ item.custNm }}
                    </span>
                    <span :class="!item.chnlCustId.trim() ? 'need-cs-card' : null" />
                  </span>
                  <!-- 고객카드 없음 240129 추가 -->
                </div>
                <div class="list-item__contents__info row">
                  <span class="crTy-bk7 fs14rem mr10">펀드명</span><span class="crTy-bk1 txtSkip maxW180">{{item.fndNm}}</span>
                </div>
                <!-- 수익증권상세조회 -->
                <div class="list-item__contents__info">
                  <mo-button componentid="" color="blue" shape="hyperlink" size="large" class="crTy-blue4 arrow-right pl0 pr0" @click.stop="fn_OpenMSPCM242P(item)">수익증권상세</mo-button>
                </div>
              </div>
              <!-- 아코디언 컨텐츠 -->
              <template v-slot:expand>
                <div class="list-item-detail">
                  <div class="contents-row">
                    <span class="dsInline crTy-bk7 fs14rem mr10 min100">계좌번호</span><span class="ml10">{{item.bfcerAccnNo}}</span>
                  </div>
                  <div class="contents-row">
                    <span class="dsInline crTy-bk7 fs14rem mr10 min100">잔고좌수</span><span class="ml10">{{$bizUtil.numberWithCommasCurr(item.blc)}}</span>
                  </div>
                  <div class="contents-row">
                    <span class="dsInline crTy-bk7 fs14rem mr10 min100">납입원금(원)</span><span class="ml10">{{$bizUtil.numberWithCommasCurr(item.padPrnc)}}</span>
                  </div>
                  <div class="contents-row">
                    <span class="dsInline crTy-bk7 fs14rem mr10 min100">세전평가금액(원)</span><span class="ml10">{{$bizUtil.numberWithCommasCurr(item.taxBefEvalAmt)}}</span>
                  </div>
                  <div class="contents-row">
                    <span class="dsInline crTy-bk7 fs14rem mr10 min100">세전수익율(%)</span><span class="ml10">{{item.taxBefBnfRat}}</span>
                  </div>
                  <div class="contents-row">
                    <span class="dsInline crTy-bk7 fs14rem mr10 min100">휴대폰번호</span><span class="ml10">{{item.custCelno}}</span>
                  </div>
                </div>
              </template>
            </mo-list-item>
          </template>
        </mo-list>
      </ur-box-container>

      <!-- NoData 영역  -->
      <ur-box-container v-else alignV="start" componentid="ur_box_container_004" direction="column" class="ns-contract-list no-data">
        <mo-list-item>
          <div class="list-item__contents">
            <div class="list-item__contents__info">
              <span>데이터가 없습니다.</span>
            </div>
          </div>
        </mo-list-item>
      </ur-box-container>

      <!-- Toast -->
      <mo-snackbar ref="snackbar" :duration="3000"></mo-snackbar>

      <!-- 하단버튼에 의해 숨겨지는 영역만큼 추가공간 확보 : 리스트 존재시에만 적용 -->
      <div v-if="cMBfcerRecmCustSVO.length > 0" class="ns-height40"></div>
      
    </ur-box-container>
  </ur-page-container>
</template>

<script>

/************************************************************************************************
* 공통 라이브러리 INCLUDE 영역
************************************************************************************************/
import Msg from '@/systems/webkit/msg/msg'
import MenuConst from '@/ui/sp/MenuConstans.js'
import MSPCM116P from '@/ui/cm/MSPCM116P' // 고객검색
import MSPCM242P from '@/ui/cm/MSPCM242P' // 수익증권상세
import MSPBottomAlert from '@/ui/_cmm/components/MSPBottomAlert'
import Screen from '~systems/mixin/screen'

export default {
  /************************************************************************************************
  * Vue 파일 속성 정의 영역
  ************************************************************************************************/
  // 현재 화면명
  name: 'MSPCM240M',
  // 현재 화면 ID
  screenId: 'MSPCM240M',
  mixins: [Screen],
  // 컴포넌트 선언
  components: {
    MSPCM116P,
    MSPCM242P,
  },

  /************************************************************************************************
  * 화면 전역 변수 선언 영역
  ************************************************************************************************/
  data () {
    return {
      // 제어 변수
      isSearched: false,  // 검색 실행 여부
      isInit: false, // 검색 초기화 여부
      isMngr: false, // 지점장 여부
      isFirst: true, // 초회 조회여부
      checkDisabled: false, // 체크박스 비활성화 여부
      isOneOrMoreCheck: false, // 전체선택
      isCustCardExist: true, // 채널아이디 여부

      // 팝업 연동
      popup116: {}, // MSPCM116P 전체고객 목록 검색
      popup242: {}, // MSPCM242P 수익증권상세조회
      lv_ReturnStr: [], // 상세조회조건 텍스트 배열
      
      // 데이터 변수
      toastMsg: '', // 문자발송 toast 문구
      pOrgData240M: {}, // 최근 조직 조회 데이터
      searchKeyword: '', // 고객명
      nowDate: this.$bizUtil.getDateToString(new Date(), ''),
      cMBfcerRecmCustSVO: [], // 인피니트 바인딩 리스트VO
      userInfo: {}, // 사용자정보 obj
      isLoading: false,
      isEmptyView: false, // 데이터 없음 화면
      lv_cnsltNo: '', // 컨설턴트 번호
      lv_inputParam: { // 서비스호출 Obj
        hofOrgNo: '', // 사업부조직번호
        dofOrgNo: '', // 지역단조직번호
        fofOrgNo: '', // 지점조직번호
        cnsltNo: '', // 컨설턴트번호
      },
      totCnt: 0, // 누적 총 조회건수
      // 더보기 key
      stndKeyList: [{stndKeyId: 'next_key', stndKeyVal: null}, {stndKeyId: 'rowCnt', stndKeyVal: 20}],
      stndKeyVal: 'start',
      pageRowCnt: '20', // 페이지 카운트

      actionSlot: {}, // (구) 사랑온 연계 버튼 (상단 버튼 Slot)
    }
  },

  /************************************************************************************************
  * Life Cycle 함수 정의 영역
  ************************************************************************************************/
  /** computed 함수 정의 영역 */
  computed: {
    selectItems() {
      return this.cMBfcerRecmCustSVO.filter(item => item.checked)
    },
    checkCount () {
      return this.cMBfcerRecmCustSVO.filter(item => item.checked).length
    },
    showReturnStr () {
      if (this.isMngr && !_.isEmpty(this.pOrgData240M)) {
        return true
      }
      if (this.lv_ReturnStr.length > 0) {
        for (let i in this.lv_ReturnStr) {
          if (!this.$bizUtil.isEmpty(this.lv_ReturnStr[i])) return true
        }
      }
      return false
    }
  },

  /** watch 정의 영역 */
  watch: {
    checkCount () {
      if (this.checkCount === this.cMBfcerRecmCustSVO.length && this.cMBfcerRecmCustSVO.length !== 0) {
        this.isOneOrMoreCheck = true
      } else {
        this.isOneOrMoreCheck = false
      }
    }
  },

  /** 설명 : Step-2 인스턴스가 작성된 후 동기적 호출 */
  created () {
    // backKey event 등록
    window.vue.getWkEvtHandler().$on(Msg.DEVICE.BACK_KEY, this.fn_HistoryBack)

    // 사용자 정보
    this.userInfo = this.getStore('userInfo').getters.getUserInfo
    this.lv_cnsltNo = this.userInfo.userId // 컨설턴트 번호

    // 지점장 여부 확인 :: 해당 사용자는 본인이 아닌 FC번호로 조회가능
    if ( this.$cmUtil.isSrchOrgCombo() === 'Y') {
      this.isMngr = true
    }

    this.fn_Init()

    // 수익증권추천고객 조회
    this.fn_Submit()
  },

  /** 설명 : Step-4 마운트 된 후 호출 */
  mounted () {
    // 화면접속로그 등록(필수)
    this.$bizUtil.insSrnLog(this.$options.screenId)
  },

  /** 설명 : Step-7 인스턴스가 Remove 되기 전 호출 */
  beforeDestroy () {
    // backKey event 해제
    window.vue.getWkEvtHandler().$off(Msg.DEVICE.BACK_KEY, this.fn_HistoryBack)
  },

  /************************************************************************************************
  * 사용자 함수 정의 영역
  ************************************************************************************************/
  methods: {
    fn_Init() {
      const lv_Vm = this

      // 상단탭
      this.actionSlot = {
        actionComponent: {
          template:'<div slot="action" class="icon-wrapper"><mo-button shape="border" class="ns-btn-round btn-title-style-1" @click="fn_SendIntent">펼쳐보기</mo-button><mo-icon type="picto" @click="fn_Search">search</mo-icon></div>',
          methods: {
            fn_SendIntent () {
              let item = {
                tbtMenuPageAttrCd: MenuConst.MENU_ATTR_CD_LTS,
                srnId: 'TSSCM340M'
              }
              this.$MenuManager.fn_SendIntentAppForMenuItem(item)
            },
            fn_Search () {
              lv_Vm.fn_OpenMSPCM116P()
            }
          }
        }
      }
    },

    fn_OnClickListItem(index) {
      if (this.checkDisabled) return
      this.$refs[`chk${index}`].onClick()
    },

    /******************************************************************************
     * Function명 : fn_HistoryBack
     * 설명       : 뒤로가기 버튼
     ******************************************************************************/
    fn_HistoryBack () {
      if (this.checkCount > 0) this.fn_confirm()
      else {
        if (this.isSearched) {
          // 조회를 한번이라도 한 경우 전체 검색
          // 초기화
          this.pOrgData240M = {}  // 조직 조회 데이터
          this.isSearched = false // 검색이력
          this.isInit = true
          this.isFirst = true
  
          // 재조회
          this.fn_Submit()
        } else {
          // 조회를 하지 않았으면 뒤로가기
          if (this.$route.query?._isMenuItem) {
            this.$router.push({name: 'MSPBC002M'})
            return
          }
          this.$router.go(-1)
        }
      }
    },

    /******************************************************************************
     * Function명 : fn_Submit
     * 설명       : 조회 버튼 클릭 이벤트
     ******************************************************************************/
    fn_Submit () {
      // 체크된 고객 초기화
      let selectItems_init = []
      this.$cmUtil.setSelectedCustList(this, selectItems_init)
      
      // user와 선택한 컨설턴트가 다를 경우 체크박스 비활성화
      if (!_.isEmpty(this.pOrgData240M) && this.isMngr === true) {
        if (this.userInfo.userId !== this.pOrgData240M.cnslt.key) {
          this.checkDisabled = true
        } else {
          this.checkDisabled = false
        }
      } else {
        // 초기 접근 시
        this.checkDisabled = false
      }

      this.totCnt = 0
      this.stndKeyVal = 'start' // 더보기 최초조회
      this.stndKeyList = [{stndKeyId: 'next_key', stndKeyVal: null}, {stndKeyId: 'rowCnt', stndKeyVal: 20}] // 초기로드 or 조회버튼시
      this.cMBfcerRecmCustSVO = [] // 인피니트 바인딩 데이터
      this.isEmptyView = false // 데이터가 없습니다 visible 여부
      this.fn_DataSet() // 조회 파라미터 세팅

      // post 호출 전 Progress 타입 설정
      window.vue.getStore('progress').dispatch('SKEL_LIST_CHK')

      this.fn_GetService() // 서비스 호출
    },

    /******************************************************************************
     * Function명 : fn_DataSet
     * 설명       : 조회전 데이터 파라미터 세팅
     ******************************************************************************/
    fn_DataSet () {
      // 초회 조회 조건 세팅
      if (this.isFirst) {
        this.pOrgData240M = {}  // 상세조회조건
        this.searchKeyword = ''
        this.lv_inputParam.cnsltNo = this.lv_cnsltNo // 컨설턴트번호
        this.lv_inputParam.dofOrgNo = this.userInfo.onpstDofNo // 지역단조직번호
        this.lv_inputParam.fofOrgNo = this.userInfo.onpstFofOrgNo // 지점조직번호
        this.lv_inputParam.hofOrgNo = this.userInfo.onpstHofOrgNo // 소속사업부조직번호

        this.isFirst = false
      }
      
      // 지점장 조회시 구분
      if (!_.isEmpty(this.pOrgData240M) && this.isMngr === true) {
        this.lv_inputParam.cnsltNo  = this.pOrgData240M.cnslt.key // 선택한 컨설턴트 번호
      } else {
        this.lv_inputParam.cnsltNo  = this.lv_cnsltNo // 컨설턴트 번호
      }
    },

    /******************************************************************************
     * Function명 : fn_GetService
     * 설명       : 서비스 호출(수익증권추천고객 조회)
     ******************************************************************************/
    fn_GetService () {
      if (this.stndKeyVal === 'end') return
      else if (this.stndKeyVal === 'start') {
        this.stndKeyList = [{stndKeyId: 'nextKey', stndKeyVal: ''}, {stndKeyId: 'rowCnt', stndKeyVal: 20}]
        this.cMBfcerRecmCustSVO = []
      }

      // const trnstId = 'txTSSCM40S1'
      const trnstId = 'txTSSCM47S1' 
      const auth = 'S'
      const lv_Vm = this
      let pParams = {cnsltNo: this.lv_inputParam.cnsltNo}
      let confirm = this.getStore('confirm')

      this.isLoading = true
      this.post(lv_Vm, pParams, trnstId, auth)
        .then(function (response) {
          // 서버 데이터 가져오기
          if (response.body !== null && response.msgComm !== null && response.msgComm.msgCd === 'EFWS003') {
            let responseList = response.body.cMBfcerRecmCustSVO

            if (responseList !== null) {
              // 리스트 체크변수 적용
              for (let i = 0; i < responseList.length; i++) {
                responseList[i].checked = false
              }

              if (lv_Vm.stndKeyVal !== 'start' ) { // 더보기
                for (let i = 0; i < responseList.length; i++) {
                  lv_Vm.cMBfcerRecmCustSVO.push(responseList[i])
                }
              } else { // 1페이지
                lv_Vm.cMBfcerRecmCustSVO = responseList
              }

              // 검색조건이 있는 경우 filter
              if (!lv_Vm.$bizUtil.isEmpty(lv_Vm.searchKeyword)) {
                let regex = new RegExp(`^${lv_Vm.searchKeyword}`)
                lv_Vm.cMBfcerRecmCustSVO = lv_Vm.cMBfcerRecmCustSVO.filter(item => regex.test(item.custNm))
              }

              if (responseList.length === 0) {
                lv_Vm.isEmptyView = true // 데이터가 없습니다 visible true
              } else {
                lv_Vm.isEmptyView = false // 데이터가 없습니다 visible false
              }

              // 조회 총 카운트
              lv_Vm.totCnt = lv_Vm.cMBfcerRecmCustSVO.length
              // 더보기 키값
              lv_Vm.stndKeyList = response.trnstComm.stndKeyList
              if (lv_Vm.stndKeyList === null || lv_Vm.stndKeyList[0] === null || lv_Vm.stndKeyList[0].stndKeyVal === null || lv_Vm.stndKeyList[0].stndKeyVal.trim() === '') {
              // 다음페이지 데이터 존재안함(마지막 페이지)
                lv_Vm.stndKeyVal = 'end'
              } else {
              // 다음페이지 데이터 존재함
                lv_Vm.stndKeyVal = 'more'
              }
            } else {
              lv_Vm.isEmptyView = true // 데이터가 없습니다 visible true
            }
          } else {
            lv_Vm.isEmptyView = true // 데이터가 없습니다 visible true
            confirm.dispatch('ADD', response.msgComm.msgDesc)
          }
          lv_Vm.isLoading = false
        })
        .catch(function (error) {
          lv_Vm.isEmptyView = true // 데이터가 없습니다 visible true
          window.vue.error(error)
        })
    },

    /******************************************************************************
      * Function명 : fn_LoadingData
      * 설명       : 인피니트 로딩데이터
    ******************************************************************************/
    fn_LoadingData () {
      if (!this.isLoading) {
        // 더보기 시 전체체크 되어있으면 전체체크 해제
        if (this.isOneOrMoreCheck && this.stndKeyVal !== 'end') {
          this.isOneOrMoreCheck = false
        }

        // post 호출 전 Progress 타입 설정
        window.vue.getStore('progress').dispatch('PART')

        this.fn_GetService()
      }
    },

    /******************************************************************************
     * Function명 : fn_CheckAll
     * 설명       : 조회목록 전체선택
     ******************************************************************************/
    fn_CheckAll () {
      this.$nextTick(() => {
        if (this.cMBfcerRecmCustSVO.length > 0) { // 리스트 존재시에만 작동
          for (let n in this.cMBfcerRecmCustSVO) {
            if (this.isOneOrMoreCheck) {
              this.cMBfcerRecmCustSVO[n].checked = true
            } else {
              this.cMBfcerRecmCustSVO[n].checked = false
            }
          }
          this.fn_CheckSelectedItemCount()
        }
      })
    },

    /******************************************************************************
     * Function명 : fn_CheckSelectedItemCount
     * 설명       : 체크박스 클릭 핸들러
     *             - 선택한 리스트 카운트를 체크한다. 30명 이상 문자전송 불가
     ******************************************************************************/
    fn_CheckSelectedItemCount (custInfo) {
      // 체크 시 BottomSheet 컨트롤
      // 공통 시트를 가리고 사용자 시트를 보여준다.
      this.$cmUtil.setSelectedCustList(this, this.selectItems)
    },

    /*********************************************************
     * Function명: fn_OpenMSPCM116P
     * 설명: 고객명 검색 팝업 호출
     *********************************************************/
    fn_OpenMSPCM116P () {
      this.popup116 = this.$bottomModal.open(MSPCM116P, {
        properties: {
          pPage: 'MSPCM240M',
          pOrgData: this.pOrgData240M, // 최근 조직 조회 데이터
          pIsMngr: this.isMngr, // 지점장 여부
          pSearchKeyword: this.searchKeyword, // 고객명
        },
        listeners: {
          onPopupSearch: (pData) => {
            // 모달 닫기
            this.$bottomModal.close(this.popup116)

            // 마지막 조직 조회 데이터 셋팅
            this.pOrgData240M = pData.rtnData.pOrgData
            if (this.isMngr) {
              this.lv_inputParam.hofOrgNo = this.pOrgData240M.hofOrg.key
              this.lv_inputParam.dofOrgNo = this.pOrgData240M.dofOrg.key
              this.lv_inputParam.fofOrgNo = this.pOrgData240M.fofOrg.key
            }
            // 조회이력 저장
            this.isSearched = true
            // 검색 초기화 여부
            this.isInit = pData.isInit
            if (this.isInit) this.isSearched = false
            // 나머지 데이터 세팅
            this.searchKeyword = pData.rtnData.searchKeyword

            // 조회조건 텍스트 세팅
            this.lv_ReturnStr = pData.srchOpt

            // 수익증권추천고객 조회
            this.fn_Submit()
          }
        }
      })
    },

    /******************************************************************************
     * Function명 : fn_OpenMSPCM242P
     * 설명       : 수익증권상세조회 팝업 연동
     ******************************************************************************/
     fn_OpenMSPCM242P (item) { 
      this.popup242 = this.$bottomModal.open(MSPCM242P, {
        properties: {
          pAcountInfo: item,
        },
        listeners: {
          onPopupClose: () => {
            this.$bottomModal.close(this.popup242)
          }
        }
      },
      {
        properties: {
          noHeader: true
        }
      })
    },

    /******************************************************************************
     * Function명 : fn_OpenCustInfoBs
     * 설명       : 고객상세 팝업 연동
     ******************************************************************************/
    fn_OpenCustInfoBs (item) {
      let lv_Vm = this
      let cnsltNo = ''
      let nmUiObject = {}

      if(this.pOrgData240M.cnslt === undefined || this.pOrgData240M.cnslt === null || this.pOrgData240M.cnslt === ''){
        cnsltNo = this.userInfo.userId
      }else{
        cnsltNo = this.pOrgData240M.cnslt.key
      }

      nmUiObject = {
        chnlCustId : item.chnlCustId, //고객채널 ID
        parentId   : 'MSPCM240M', //화면ID+
        cnsltNo : cnsltNo,
        custNm : item.custNm
      }

      lv_Vm.$cmUtil.fn_OpenCustNmUiCard(this, nmUiObject,(modalId)=>{
         //Close 콜백 처리
         return
      })
    },

    /******************************************************************************
     * @description confirm popup
     * @param {String} title 제목
     * @param {String} message confirm 내용
     ******************************************************************************/
    fn_confirm(title, message) {
      let lv_Vm = this
      let backMsg = '선택을 그만두고<br>이전 화면으로 이동하시겠습니까?'
      let lv_AlertPop = this.$bottomModal.open(MSPBottomAlert, {
          properties: {
            type: 'B',
            content: backMsg,
            title_pos_btn: "예",
            title_neg_btn: "아니오"
          },
          listeners: {
            onPopupConfirm: () => {
              lv_Vm.$bottomModal.close(lv_AlertPop)
              if (this.isSearched) {
                // 조회를 한번이라도 한 경우 전체 검색
                // 초기화
                this.pOrgData240M = {}  // 조직 조회 데이터
                this.isSearched = false // 검색이력
                this.isInit = true
                this.isFirst = true

                // 재조회
                this.fn_Submit()
              } else {
                // 조회를 하지 않았으면 뒤로가기
                if (this.$route.query?._isMenuItem) {
                  this.$router.push({name: 'MSPBC002M'})
                  return
                }
                this.$router.go(-1)
              }
            },
            onPopupClose: () => {
              lv_Vm.$bottomModal.close(lv_AlertPop)
            }
          }
        })
    },
  }
}
</script>
